import Axios from "axios";
import sweetalert2 from "sweetalert2";

import environment from "@/environment";
import { wait } from "@/helpers/common";

class ComumService {
	axios;
	comumUrl;
	_ready;

	constructor () {
		this.axios = Axios.create({ baseURL: `${environment.API}/v1`});
		this.comumUrl = "https://hmg.barkfinder.suzano.tcsapp.com.br/api";
		//this.comumUrl = "localhost:4004/api"
		this._ready = true;
		/*this.axios.get("modulos").then(resp => {
			console.log("bnan")
			this.comumUrl = resp.data.url;
			console.log(this.comumUrl)
			console.log(resp)
		}).catch(error => {
			sweetalert2({
				title: "Falha ao obter módulos!",
				type: "error",
				html: `<p>${error.response ? error.response.data.message : error.message}</p>`,
				confirmButtonText: "Fechar",
				confirmButtonColor: "#6c757d"
			});
		}).finally(() => this._ready = true);*/
	}

	async _isReady () {
		while (!this._ready) await wait(50);
	}

	async getComumUrl () {
		await this._isReady();
		return this.comumUrl;
	}

	async getModules () {
		await this._isReady();
		const resp = await this.axios.get(`${this.comumUrl}/api/v1/modulos`);
		return resp.data;
	}
}

export default new ComumService();
